import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Report from "./Styles/ReportsStyle.module.css";
import Table from "../../components/Table/UserTable";
import "./TransactionListing.scss";
import moment from "moment";
import "../../components/style/react-datepicker.css";
import DateComponent from "../../components/datespicker/DatePicker";
import SearchComponent from "../../components/search/SearchComponent";
import FilterButton from "../../components/search/FilterButton";
import { FormatCurrency } from "../../utils/GlobalUtils/Currency";
import TableActionsCustom from "../../components/Table/TableControlsCustom";
import {
  GetAccessAgentTransactions,
  GetAccessAgentTransactionsDownlines,
} from "../../utils/redux/Reports/ReportsSlice";
import { FormatDateTime } from "../../utils/GlobalUtils/DateFormater";
import DropDownComponent from "../../components/dropdown/DropDownComponent";
import { USER_TYPE } from "../../utils/GlobalUtils/Enums";
import { GetLocalStorageUser } from "../../utils/GlobalUtils/GetLocalStorageValues";
import { ExportToExcel } from "../../sharedComponent/ExportFile/ExportFile";

const VIEW = {
  MY_OWN: "own",
  MY_OWN_DOWNLINES: "downlines",
};

export const statusProvider = [
  {
    key: "",
    name: "All",
  },
  {
    key: "Electricity",
    name: "Electricity",
  },
  {
    key: "Cashout",
    name: "Cashout",
  },
  {
    key: "Transfer",
    name: "Transfer",
  },
  {
    key: "Airtime",
    name: "Airtime",
  },
  {
    key: "Data",
    name: "Data",
  },
  {
    key: "Access-Africa",
    name: "Access Africa",
  },
];

function TransactionListing() {
  const { agentRole } = GetLocalStorageUser();
  const [viewType, setViewType] = useState(VIEW.MY_OWN);
  const dispatch = useDispatch();
  const agent_transactions = useSelector(
    (state) => state?.reports?.agent_transactions?.responseModel
  );
  const [status, setStatus] = useState("");

  const isLoading = useSelector((state) => state?.performance?.loading);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");

  const [submit, setSubmit] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "Trans. ID",
        accessor: "transactionReference",
        Cell: ({ value, row }) => {
          const { original } = row;
          return (
            <Box className="">
              <span>{original?.transactionReference?.slice(0, 12)}</span> <br />
              <span>
                {original?.transactionReference?.slice(12, original?.length)}
              </span>
            </Box>
          );
        },
      },
      {
        Header: "Date",
        Cell: ({ value, row }) => {
          const { original } = row;
          // //console.log(original);
          return (
            <Box className="">
              <span>
                {FormatDateTime(
                  original?.transactionDate,
                  "DD/MM/YYYY hh:mm:ss"
                )}
              </span>
            </Box>
          );
        },
      },
      {
        Header: "Name",
        accessor: "agentName",
      },
      {
        Header: "Transaction Type",
        accessor: "transactionType",
      },
      {
        Header: "Amount",
        accessor: "transactionAmount",
        Cell: ({ value, row }) => {
          const { original } = row;
          return (
            <Box className="">
              <span>{FormatCurrency(original?.transactionAmount)}</span>
            </Box>
          );
        },
      },
      {
        Header: "Commission",
        accessor: "resellerCommission",
        Cell: ({ value, row }) => {
          const { original } = row;
          return (
            <Box className="">
              <span>{FormatCurrency(original?.resellerCommission)}</span>
            </Box>
          );
        },
      },
      {
        Header: "Charge",
        accessor: "charges",
        Cell: ({ value, row }) => {
          const { original } = row;
          return (
            <Box className="">
              <span>{FormatCurrency(original?.charges)}</span>
            </Box>
          );
        },
      },
      {
        Header: "Narration",
        accessor: "narration",
        Cell: ({ value, row }) => {
          const { original } = row;
          return (
            <Box className="">
              <span>{original?.narration?.slice(0, 25)}</span> <br />
              <span>{original?.narration?.slice(25, original?.length)}</span>
            </Box>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const tableData = useMemo(
    () => agent_transactions?.result || [],
    [agent_transactions?.result]
  );

  const excelData = tableData.map((row) => {
    return {
      Date: FormatDateTime(row?.transactionDate, "DD/MM/YYYY hh:mm:ss"),
      "Transaction ID": row?.transactionReference,
      Name: row?.agentName,
      "Transaction Type": row?.transactionType,
      Amount: row?.transactionAmount,
      Commission: row?.resellerCommission,
      Charge: row?.charges,
      Status: row?.transctionStatus,
      Narration: row?.narration,
    };
  });

  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 60))
  );
  const [endDate, setEndDate] = useState(new Date());

  const filterStartDate = moment(startDate).format("yy-MM-DD");
  const filterEndDate = moment(endDate).format("yy-MM-DD");

  // inward && cashout pos
  // const [loading, setLoading] = useState(false);

  const dataValues = {
    currentPage: page,
    pageSize: limit,
    startDate: filterStartDate,
    endDate: filterEndDate,
    searchText: query,
    transactionType: status,
    status: "SUCCESSFUL",
  };

  const MakeAPICall = () => {
    if (viewType === VIEW.MY_OWN_DOWNLINES) {
      return dispatch(GetAccessAgentTransactionsDownlines(dataValues));
    }

    return dispatch(GetAccessAgentTransactions(dataValues));
  };

  useEffect(() => {
    MakeAPICall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, submit, viewType]);

  return (
    <React.Fragment>
      <section>
        {agentRole === USER_TYPE.AGENT && (
          <Box>
            <ul className={Report.Nav}>
              <li
                className={`${Report.Nav_Filters} ${
                  viewType === VIEW.MY_OWN && Report.Nav_Filters_Active
                }`}
                onClick={() => {
                  setViewType(VIEW.MY_OWN);
                  setPage(1);
                }}
              >
                My Transactions
              </li>
              <li
                className={`${Report.Nav_Filters} ${
                  viewType === VIEW.MY_OWN_DOWNLINES &&
                  Report.Nav_Filters_Active
                }`}
                onClick={() => {
                  setViewType(VIEW.MY_OWN_DOWNLINES);
                  setPage(1);
                }}
              >
                My Downlines
              </li>
            </ul>
          </Box>
        )}

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            flexWrap: {
              xs: "wrap",
              md: "no-wrap",
            },
          }}
        >
          <p></p>
          <Stack direction="row" spacing={2} sx={{ my: 2 }}>
            <ExportToExcel
              fileName="Transaction Listing Report"
              data={excelData}
            />
          </Stack>
        </Stack>

        <div className={Report.Filters}>
          <DateComponent
            date={startDate}
            setDate={setStartDate}
            maxDate={new Date()}
          />
          <DateComponent
            date={endDate}
            setDate={setEndDate}
            maxDate={new Date()}
          />
          <SearchComponent
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            name={"name"}
          />
          <DropDownComponent
            array={statusProvider}
            onChange={(e) => setStatus(e.target.value)}
            value={status}
            name={"demo"}
          />
          <FilterButton
            onClick={() =>
              // handleDateFilter();
              setSubmit(!submit)
            }
          />
        </div>

        <Box className="all_table_datas">
          <Table columns={columns} data={tableData} isLoading={isLoading} />
          <TableActionsCustom
            first_page={1}
            present_page={agent_transactions?.currentPage || 1}
            last_page={agent_transactions?.totalPages}
            click={(page) => setPage(page)}
            pageSize={limit}
            setPageSize={setLimit}
            totalRows={agent_transactions?.totalRows || 0}
          />
        </Box>
      </section>
    </React.Fragment>
  );
}

export default TransactionListing;
