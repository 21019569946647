import React, { lazy, Suspense, useEffect } from "react";
import { Helmet } from "react-helmet";
// import { v4 as uuidv4 } from "uuid";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // useLocation,
  // useNavigate,
  // Navigate,
  Outlet,
  // useSearchParams,
} from "react-router-dom";
import "./assets/globalstyle/globalstyle.css";

import { ToastContainer as DefaultToastContainer } from "react-toastify";

// Import Contexts
import { theme, ThemeProvider } from "./context/ThemeProvider";
// import SuspenseFallback from "./components/SuspenseFallback";
// import ErrorBoundary from "./components/ErrorBoundary";
import NoPage from "./components/UnknownPage";

// Other assets
import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";

// Import Pages
import Dashboard from "./pages/Dashboard";
// import { useSelector } from "react-redux";
import {
  LoggedInRoute,
  ProtectedRoutes,
} from "./utils/GlobalUtils/ProtectedRoutes";
import ChangeBankAccount from "./layouts/Settings/ChangeBankAccount";
import { GetLocalStorageUser } from "./utils/GlobalUtils/GetLocalStorageValues";
import { URLs } from "./components/Drawer/DrawerContext/URLEnums";
import { USER_TYPE } from "./utils/GlobalUtils/Enums";
// import LogoutUser from "./utils/GlobalUtils/Logout";

const Onboarding = lazy(() =>
  import("./pages/authPages/onboarding/Onboarding")
);

const SetPassword = lazy(() =>
  import("./pages/authPages/onboarding/SetPassword")
);

const FinalOnboarding = lazy(() =>
  import("./pages/authPages/onboarding/FinalOnboarding")
);

const ForgotPassword = lazy(() =>
  import("./pages/authPages/forgotPassword/ForgotPassword")
);

const OtpPassword = lazy(() =>
  import("./pages/authPages/otpPassword/OtpPassword")
);

const ResetPassword = lazy(() =>
  import("./pages/authPages/resetPassword/ResetPassword")
);

const ResetPasswordSuccess = lazy(() =>
  import("./pages/authPages/resetPasswordSuccess/ResetPasswordSuccess")
);

const AccountSetup = lazy(() =>
  import("./components/Account-setup/AccountSetup")
);

const DashboardIndexSection = lazy(() => import("./layouts/dashboard/Index"));
const Performance = lazy(() => import("./layouts/dashboard/performance"));

const Showcase = lazy(() => import("./layouts/Showcase"));

const ResellerPerformanceView = lazy(() =>
  import("./layouts/Performance/ResellerPerformanceView")
);
const AgentPerformanceView = lazy(() =>
  import("./layouts/Performance/AgentPerformanceView")
);
const ProductsForAgentPerformance = lazy(() =>
  import("./layouts/Performance/ProductsForAgentPerformance")
);
const AgentPerformanceViewById = lazy(() =>
  import("./layouts/Performance/AgentPerformanceViewById")
);

const Users = lazy(() => import("./layouts/UserManagement/Users"));
const Wallets = lazy(() => import("./layouts/Wallets/Wallets"));
const Customers = lazy(() => import("./layouts/UserManagement/Customers"));

const MakerChecker = lazy(() =>
  import("./layouts/UserManagement/MakerChecker")
);

// const Roles = lazy(() => import("./layouts/RoleManagement/Roles"));
const Commission = lazy(() => import("./layouts/Commission/Commission"));

// const Roles = lazy(() => import("./layouts/RoleManagement/Roles"));

const WIPAYReports = lazy(() => import("./layouts/WIPAY/WIPAY"));

const CustomerListing = lazy(() =>
  import("./layouts/Report/AgentListing/CustomerListing")
);
const SingleCustomerListing = lazy(() =>
  import("./layouts/Report/AgentListing/SingleCustomerListing")
);
const TransactionListing = lazy(() =>
  import("./layouts/Report/TransactionListing")
);

const OpenInvoiceDetails = lazy(() =>
  import("./layouts/Invoicing/OpenInvoiceDetails")
);

const OpenInvoiceView = lazy(() => import("./layouts/Invoicing/OpenInvoice"));
const ClosedInvoiceView = lazy(() =>
  import("./layouts/Invoicing/ClosedInvoice")
);

const CashoutReport = lazy(() => import("./layouts/Report/CashoutReport"));
const CommissionWallet = lazy(() =>
  import("./layouts/Report/CommissionWallet")
);
const AirtimeReport = lazy(() => import("./layouts/Report/AirtimeReport"));
const AccessAfricaReport = lazy(() =>
  import("./layouts/Report/AccessAfricaReport")
);
const PendingTransaction = lazy(() =>
  import("./layouts/Report/PendingTransaction")
);
const AccessTransferReport = lazy(() =>
  import("./layouts/Report/AccessTransferReport")
);
const DataReport = lazy(() => import("./layouts/Report/DataReport"));
const BillsReport = lazy(() => import("./layouts/Report/BillsReport"));
const TransactionSummaryModule = lazy(() =>
  import("./layouts/Report/TransactionSummaryModule")
);
const LienTransaction = lazy(() => import("./layouts/Report/LienTransaction"));
// const ViewSubclusterLots = lazy(() =>
//   import("./layouts/Report/ViewSubCluster")
// );

const DebitCustomerModule = lazy(() =>
  import("./layouts/DebitCustomerModule/DebitCustomerModule")
);

// const InputTracking = lazy(() => import("./layouts/InputTracking/Input"));

const AirtimeProducts = lazy(() =>
  import("./layouts/Products/airtime/Airtime")
);
// const DataProducts = lazy(() => import("./layouts/Products/data/data"));
const CableTelevision = lazy(() =>
  import("./layouts/Products/cable-tv/CableTelevision")
);
const Electricity = lazy(() =>
  import("./layouts/Products/electricity/Electricity")
);

const FundWallet = lazy(() => import("./layouts/AgencyBanking/FundWallet"));
const Transfer = lazy(() => import("./layouts/AgencyBanking/Transfer"));
const CashOut = lazy(() => import("./layouts/AgencyBanking/CashOut"));
const AccessAfrica = lazy(() => import("./layouts/AgencyBanking/AccessAfrica"));
const WithdrawMoney = lazy(() => import("./layouts/AgencyBanking/Withdraw"));

const PendingFund = lazy(() => import("./layouts/FundingModule/PendingFund"));
const FundingModule = lazy(() =>
  import("./layouts/FundingModule/FundingModule")
);

const TransactionPin = lazy(() => import("./layouts/Settings/TransactionPin"));

const PaymentTransactions = lazy(() =>
  import("./layouts/Settings/Transactions")
);
const PaymentDisbursments = lazy(() =>
  import("./layouts/Settings/ChangePassword")
);
// const ChangeBankAccount = lazy(() =>
//   import("./layouts/Settings/ChangeBankAccount")
// );

const CardNetwork = lazy(() => import("./layouts/CardNetwork/CardNetwork"));

const Login = lazy(() => import("./pages/authPages/login/Login"));

const Holding = lazy(() => import("./pages/authPages/login/HoldingPage"));

// const Test = lazy(() => import("./pages/Test"));

// create a default container so we can override the styles
const ToastContainer = (props) => (
  <DefaultToastContainer style={{ zIndex: "1900" }} {...props} />
);

function Main() {
  const userData = GetLocalStorageUser("user_profile");
  const data = GetLocalStorageUser();

  const PageTitle = (role) => {
    if (role === USER_TYPE.AGENT) {
      return "Access Bank - Agent Portal";
    }

    if (role === USER_TYPE.TELLER) {
      return "Access Bank - Teller Portal";
    }

    return "Access Bank";
  };

  useEffect(() => {
    document.title = PageTitle(data?.agentRole);
  }, [data, userData]);
  return (
    <div>
      <Helmet>
        <meta
          http-equiv="Content-Security-Policy"
          content={`default-src 'none'; style-src 'unsafe-inline' https://*.ucard.store/ https://fonts.googleapis.com/ https://fonts.gstatic.com/; style-src-elem 'unsafe-inline' https://*.ucard.store/ https://fonts.googleapis.com/ https://fonts.gstatic.com/;  font-src  https://*.ucard.store/ https://fonts.googleapis.com/ https://fonts.gstatic.com/; script-src 'self' https://*.ucard.store/; connect-src 'self' https://*.ucard.store/ https://devapi.ucard.store/; img-src 'self' https://*.ucard.store/; manifest-src 'self' https://*.ucard.store/; `}
        />
      </Helmet>
      <Suspense fallback={"Loading..."}>
        <ThemeProvider theme={theme}>
          <Router>
            <Routes>
              {/* <Route exact path='/' element={<Login />} /> */}
              <Route path="/confirm-registration" element={<SetPassword />} />
              <Route path="/set-password" element={<SetPassword />} />
              <Route
                path="/successful-onboarding"
                element={<FinalOnboarding />}
              />
              {/* <Route path='/login' element={(!(auth?.access_token) ? <Login /> : <Navigate to='/account-setup' false/>)} /> */}
              <Route element={<LoggedInRoute />}>
                <Route path="/" element={<Login />} />
              </Route>
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/otp-password" element={<OtpPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="/reset-password-success"
                element={<ResetPasswordSuccess />}
              />

              {/* DASHBOARD */}
              <Route element={<ProtectedRoutes />}>
                <Route path={"user"} element={<Holding />} />
                <Route path="/dashboard" element={<Dashboard />}>
                  {/* <Route index element={""} /> */}
                  <Route index element={<DashboardIndexSection />} />
                  {/* <Route path="performance" element={<p>Performance</p>} /> */}
                  <Route path="account-setup" element={<AccountSetup />} />
                  <Route path={"showcase"} element={<Showcase />} />
                  <Route path="settings" element={<Outlet />}>
                    <Route index element={""} />
                    <Route
                      path="change-pin"
                      element={<PaymentTransactions />}
                    />
                    <Route
                      path="change-password"
                      element={<PaymentDisbursments />}
                    />
                    <Route
                      path="set-transaction-pin"
                      element={<TransactionPin />}
                    />
                    <Route
                      path="change-bank-account"
                      element={<ChangeBankAccount />}
                    />
                  </Route>
                  <Route
                    path="wallets"
                    element={userData?.role === 4 ? <NoPage /> : <Wallets />}
                  />

                  <Route
                    path={URLs.TELLER_MGT}
                    element={
                      data?.agentRole !== USER_TYPE.AGENT ? (
                        <NoPage />
                      ) : (
                        <Customers />
                      )
                    }
                  />
                  {/* <Route
                      index
                      element={
                        data?.agentRole !== USER_TYPE.AGENT ? <NoPage /> : ""
                      }
                    />
                    {/* <Route
                      path="users"
                      element={userData?.role !== "4" ? <NoPage /> : <Users />}
                    />
                    <Route
                      path="marker-checker"
                      element={
                        userData?.role !== "4" ? <NoPage /> : <MakerChecker />
                      }
                    /> */}
                  {/* <Route
                      path={URLs.TELLER_PAGE}
                      element={
                        data?.agentRole !== USER_TYPE.AGENT ? (
                          <NoPage />
                        ) : (
                          <Customers />
                        )
                      }
                    /> */}
                  {/* </Route> */}

                  <Route path="products" element={<Outlet />}>
                    <Route index element={""} />
                    <Route path="airtime" element={<AirtimeProducts />} />
                    {/* <Route path="data" element={<DataProducts />} /> */}
                    <Route
                      path="cable-television"
                      element={<CableTelevision />}
                    />
                    <Route path="electricity" element={<Electricity />} />
                  </Route>

                  <Route path="agency-banking" element={<Outlet />}>
                    <Route index element={""} />
                    <Route path="fund" element={<FundWallet />} />
                    <Route path="transfer" element={<Transfer />} />
                    <Route path="cash-out" element={<CashOut />} />
                    <Route path="wallet" element={<WithdrawMoney />} />
                    <Route path="access-africa" element={<AccessAfrica />} />
                    {/* <Route path="electricity" element={<Electricity />} /> */}
                  </Route>

                  {/* <Route path="roles" element={<Roles />} /> */}

                  <Route path="report" element={<Outlet />}>
                    <Route index element={<DashboardIndexSection />} />
                    {/* <Route index element={""} /> */}
                    <Route path="agent-listing" element={<Outlet />}>
                      <Route index element={<CustomerListing />} />
                      <Route path=":id" element={<SingleCustomerListing />} />
                    </Route>
                    <Route
                      path="transaction-listing"
                      element={<TransactionListing />}
                    />
                    <Route path="main-wallet" element={<CashoutReport />} />
                    <Route
                      path="commission-wallet"
                      element={<CommissionWallet />}
                    />
                    <Route
                      path="transaction-summary"
                      element={<TransactionSummaryModule />}
                    />
                    <Route path="airtime-report" element={<AirtimeReport />} />
                    <Route
                      path="access-africa"
                      element={<AccessAfricaReport />}
                    />
                    <Route path="pending" element={<PendingTransaction />} />
                    <Route path="transfer" element={<AccessTransferReport />} />
                    <Route path="data-report" element={<DataReport />} />
                    <Route path="bills-report" element={<BillsReport />} />
                    <Route
                      path="lien-transaction"
                      element={<LienTransaction />}
                    />
                  </Route>
                  <Route path="invoice" element={<Outlet />}>
                    <Route index element={<DashboardIndexSection />} />
                    {/* <Route index element={""} /> */}
                    <Route path="open" element={<Outlet />}>
                      <Route index element={<OpenInvoiceView />} />
                      <Route path=":id" element={<OpenInvoiceDetails />} />
                    </Route>
                    <Route path="closed" element={<Outlet />}>
                      <Route index element={<ClosedInvoiceView />} />
                      <Route path=":id" element={<OpenInvoiceDetails />} />
                    </Route>
                    <Route
                      path="transaction-listing"
                      element={<TransactionListing />}
                    />
                    <Route path="main-wallet" element={<CashoutReport />} />
                    <Route
                      path="commission-wallet"
                      element={<CommissionWallet />}
                    />
                    <Route
                      path="transaction-summary"
                      element={<TransactionSummaryModule />}
                    />
                    <Route path="airtime-report" element={<AirtimeReport />} />
                    <Route path="data-report" element={<DataReport />} />
                    <Route path="bills-report" element={<BillsReport />} />
                    <Route
                      path="lien-transaction"
                      element={<LienTransaction />}
                    />
                  </Route>
                  <Route path="other-reports" element={<Outlet />}>
                    <Route index element={<DashboardIndexSection />} />
                    <Route path="wipay" element={<WIPAYReports />} />
                  </Route>
                  <Route path="card-network" element={<CardNetwork />} />
                  <Route path={"performance"} element={<Performance />} />
                  <Route
                    path="performance-management/reseller"
                    element={<ResellerPerformanceView />}
                  />
                  <Route
                    path="performance-management/agent"
                    element={<AgentPerformanceView />}
                  />
                </Route>

                <Route path="agent-management" element={<Outlet />}>
                  <Route index element={""} />
                  <Route path="users" element={<Users />} />
                  <Route path="marker-checker" element={<MakerChecker />} />
                  <Route path="agents" element={<Customers />} />
                </Route>

                {/* <Route path="roles" element={<Roles />} /> */}

                <Route path="report" element={<Outlet />}>
                  <Route index element={<DashboardIndexSection />} />
                  {/* <Route index element={""} /> */}
                  <Route path="agent-listing" element={<Outlet />}>
                    <Route index element={<CustomerListing />} />
                    <Route path=":id" element={<SingleCustomerListing />} />
                  </Route>

                  <Route
                    path="performance-management/agent/products/:id"
                    element={<ProductsForAgentPerformance />}
                  />
                  <Route
                    path="performance-management/agent/:id"
                    element={<AgentPerformanceViewById />}
                  />
                  <Route
                    path="debit-customer-module"
                    element={<DebitCustomerModule />}
                  />
                  <Route path="commissions" element={<Commission />} />
                  <Route path="funding" element={<Outlet />}>
                    <Route index element={""} />
                    <Route path="customer-wallet" element={<FundingModule />} />
                    <Route path="pending-fund" element={<PendingFund />} />
                  </Route>
                  <Route path="*" element={<NoPage />} />
                </Route>

                <Route path="*" element={<NoPage />} />
              </Route>
            </Routes>
          </Router>
          <ToastContainer />
        </ThemeProvider>
      </Suspense>
    </div>
  );
}

export default Main;
