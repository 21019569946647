import Bills from "../../assets/img/q_bills.svg";
import Withdraw from "../../assets/img/q_withdraw.svg";
import Airtime from "../../assets/img/q_data.svg";
import Transfer from "../../assets/img/q_transfer.svg";
import Add from "../../assets/img/q_add.svg";

export const QuickActions = () => {
  const QuickActions = {
    Actions: [
      {
        name: "Buy Airtime",
        icon: Add,
        url: "/dashboard/products/airtime",
      },
      // {
      //   name: "Buy Data",
      //   icon: Airtime,
      //   url: "/dashboard/products/data",
      // },
      {
        name: "EDSA",
        icon: Bills,
        url: "/dashboard/products/electricity",
      },
      {
        name: "Cashout",
        icon: Withdraw,
        url: "/dashboard/agency-banking/cash-out",
      },
      {
        name: "Transfer",
        icon: Transfer,
        url: "/dashboard/agency-banking/transfer",
      },
    ],
  };

  return QuickActions;
};
